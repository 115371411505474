<template>
  <popup
    ref="popupRefs"
    :async="true"
    width="550px"
    title="余额充值"
    :confirm-button="confirmText"
    :cancel-button="cancelText"
    @confirm="onConfirm"
    @close="onClose"
  >
    <el-form
      ref="formRefs"
      v-loading="loading"
      :model="form"
      :rules="rules"
      label-width="80px"
      size="small"
    >
      <el-form-item label="充值金额" prop="money">
        <div>
          <el-input v-model="form.money" :disabled="isPay"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="支付方式" prop="type">
        <div>
          <el-radio-group v-model="form.type" :disabled="isPay">
            <el-radio label="alipay">支付宝</el-radio>
            <el-radio label="wxpay">微信支付</el-radio>
            <el-radio label="usdt">USDT（TRC20）</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
    </el-form>
  </popup>
</template>

<script>
import Popup from "@/components/popup/index.vue";
import { apiRecharge } from "@/api/user";

export default {
  components: {
    Popup,
  },
  data() {
    const moneyValidator = (rule, value, callback) => {
      const regex = /^\d+(\.\d{1,2})?$/;
      if (!regex.test(value))
        callback(new Error("请输入正确的充值金额，保留两位小数"));
      callback();
    };
    return {
      isPay: false,
      confirmText: "确认充值",
      cancelText: "取消充值",
      loading: false,
      form: {
        money: "",
        type: "alipay",
      },
      rules: {
        money: [
          {
            required: true,
            message: "请输入充值金额",
            trigger: ["blur"],
          },
          {
            validator: moneyValidator,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openDialog() {
      this.isPay = false;
      this.$refs.popupRefs.open();
    },
    closeDialog() {
      this.$refs.popupRefs.close();
    },
    onClose() {
      this.$nextTick(() => {
        this.$refs.formRefs.clearValidate();
        this.$refs.formRefs.resetFields();
      });
    },
    openPay(url) {
      window.open(url, "_blank");
    },
    onConfirm() {
      if (this.isPay === false) {
        this.$refs.formRefs.validate((valid) => {
          if (!valid) return;
          const api = apiRecharge(this.form);
          api.then((resp) => {
            this.confirmText = "已充值";
            this.cancelText = "未充值";
            this.isPay = true;
            this.openPay(resp.payurl);
          });
        });
      } else if (this.isPay === true) {
        this.closeDialog();
        this.confirmText = "确认充值";
        this.cancelText = "取消充值";
        this.isPay = false;
        this.$emit("refresh");
      }
    },
  },
};
</script>
