<template>
  <popup
    ref="popupRefs"
    :async="true"
    width="450px"
    title="购买授权"
    confirm-button="确认"
    @confirm="onConfirm"
    @close="onClose"
  >
    <el-form
      ref="formRefs"
      v-loading="loading"
      :model="form"
      :rules="rules"
      label-width="80px"
      size="small"
    >
      <el-form-item label="套餐类型" prop="type">
        <el-select
          v-model="form.type"
          placeholder="请选择套餐类型"
          @change="onChange"
          style="width: 100%"
        >
          <el-option
            v-for="item in combo"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="套餐价格" prop="money">
        <div>
          <el-input v-model="form.money" :readonly="true"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="授权天数" prop="days">
        <div>
          <el-input v-model="form.days" :readonly="true"></el-input>
        </div>
      </el-form-item>
    </el-form>
  </popup>
</template>

<script>
import Popup from "@/components/popup/index.vue";

import { apiListCombo } from "@/api/combo";
import { apiRenew } from "@/api/user";

export default {
  components: {
    Popup,
  },
  data() {
    return {
      identity: null,
      loading: false,
      combo: [],
      form: {
        type: "",
        money: "",
        days: "",
      },
      rules: {
        type: [
          {
            required: true,
            message: "请选择套餐类型",
            trigger: ["blur"],
          },
        ],
        money: [
          {
            required: true,
            message: "套餐价格不能为空",
            trigger: ["blur"],
          },
        ],
        days: [
          {
            required: true,
            message: "授权天数不能为空",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    getDetail() {
      this.loading = true;
      apiListCombo({}).then((resp) => {
        this.combo = resp.list;
        this.loading = false;
      });
    },
    openDialog() {
      this.getDetail();
      this.$refs.popupRefs.open();
    },
    closeDialog() {
      this.$refs.popupRefs.close();
    },
    onClose() {
      this.$nextTick(() => {
        this.$refs.formRefs.clearValidate();
        this.$refs.formRefs.resetFields();
      });
    },
    onConfirm() {
      this.$refs.formRefs.validate((valid) => {
        if (!valid) return;
        const api = apiRenew(this.form);
        api.then(() => {
          this.closeDialog();
          this.$message({
            type: "success",
            message: "购买成功！",
          });
          this.$emit("refresh");
        });
      });
    },
    onChange(e) {
      this.combo.forEach((item) => {
        if (item.id === e) {
          this.form.money = item.money;
          this.form.days = item.days;
        }
      });
    },
  },
};
</script>
