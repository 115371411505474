import request from "@/utils/request";

export function apiResetKey() {
  return request.post("/user/resetKey");
}

export function apiRecharge(params) {
  return request.post("/pay/pay", params);
}

export function apiRenew(params) {
  return request.post("/user/renew", params);
}
