<template>
  <div>
    <el-card v-loading="loading" shadow="never">
      <div slot="header"><b>账户信息</b></div>
      <el-form ref="formRef" :model="form" size="small" label-width="80px">
        <el-form-item label="用户编号" prop="id">
          {{ form.id }}
        </el-form-item>
        <el-form-item label="用户账号" prop="account">
          {{ form.account }}
          <!--          <span style="margin-left: 10px;"><el-button type="text" @click="rePwd()">重置密码</el-button></span>-->
        </el-form-item>
<!--        <el-form-item label="用户名称" prop="name">-->
<!--          {{ form.name }}-->
          <!--          <span style="margin-left: 10px;"><el-button type="text" @click="rename()">修改名称</el-button></span>-->
<!--        </el-form-item>-->
        <el-form-item label="账户余额" prop="money">
          <b>{{ form.money }}</b>
          <span style="margin-left: 10px"
          ><el-button type="text" @click="recharge()"
          >余额充值</el-button
          ></span
          >
        </el-form-item>
<!--        <el-form-item label="接口密钥" prop="apikey">-->
<!--          <span>{{ form.apikey ? form.apikey : "" }}</span>-->
<!--          <span v-if="!form.apikey"-->
<!--          ><el-button type="text" @click="resetKey('new')"-->
<!--          >生成接口密钥</el-button-->
<!--          ></span-->
<!--          >-->
<!--          <span v-else style="margin-left: 10px"-->
<!--          ><el-button type="text" @click="resetKey()"-->
<!--          >重置接口密钥</el-button-->
<!--          ></span-->
<!--          >-->
<!--        </el-form-item>-->
<!--        <el-form-item label="授权到期" prop="expiry_time">-->
<!--          {{-->
<!--            form.expiry_time-->
<!--              ? formatDate(form.expiry_time, "yyyy-mm-dd hh:MM:ss")-->
<!--              : "未购买"-->
<!--          }}-->
<!--          <span style="margin-left: 10px"-->
<!--          ><el-button type="text" @click="buyAuth()"-->
<!--          >购买授权</el-button-->
<!--          ></span-->
<!--          >-->
<!--        </el-form-item>-->
        <el-form-item label="注册时间" prop="create_time">
          {{ formatDate(form.create_time, "yyyy-mm-dd hh:MM:ss") }}
        </el-form-item>
        <!--        <el-form-item label="最近登录时间" prop="login_time">-->
        <!--          {{form.login_time}}-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="最近登录IP" prop="login_ip">-->
        <!--          {{form.login_ip}}-->
        <!--        </el-form-item>-->
      </el-form>
    </el-card>

    <!--    <pwd-dialog ref="pwdRefs" @refresh="getData"></pwd-dialog>-->
    <recharge-dialog ref="rechargeRefs" @refresh="getData"></recharge-dialog>
    <auth-dialog ref="authRefs" @refresh="getData"></auth-dialog>
  </div>
</template>

<script>
import {apiGetMy} from "@/api/passport";
import {apiResetKey} from "@/api/user";
import {formatDate} from "@/utils/util";

// import PwdDialog from "./pwdDialog.vue";
import RechargeDialog from "./rechargeDialog.vue";
import AuthDialog from "./authDialog.vue";

export default {
  components: {
    // PwdDialog,
    RechargeDialog,
    AuthDialog},
  data() {
    return {
      loading: false,
      form: {
        id: "",
        account: "",
        name: "",
        apikey: "",
        money: "",
        create_time: "",
        expiry_time: "",
        login_time: "",
        login_ip: "",
      },
      rules: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    formatDate,
    getData() {
      this.loading = true;
      apiGetMy().then((resp) => {
        console.log(resp.id);
        this.form.id = resp.id;
        this.form.account = resp.account;
        this.form.name = resp.name;
        this.form.apikey = resp.apikey;
        this.form.money = resp.money;
        this.form.create_time = resp.create_time;
        this.form.expiry_time = resp.expiry_time;
        this.form.login_time = resp.login_time;
        this.form.login_ip = resp.login_ip;
        this.loading = false;
      });
    },
    rePwd() {
      this.$refs.pwdRefs.openDialog();
      // this.$message.success("修改密码功能暂未开放");
    },
    rename() {
      // this.$message.success("修改密码功能暂未开放");
    },
    recharge() {
      this.$refs.rechargeRefs.openDialog();
      // this.$message.success("充值功能暂未开放");
    },
    resetKey(e) {
      let msg = "";
      if (e === "new") {
        msg = "确定要生成接口密钥吗？";
      } else {
        msg = "确定要重置接口密钥吗？";
      }
      this.$confirm(msg)
        .then(() => {
          // this.loading = true;
          apiResetKey().then(() => {
            this.getData();
            //   this.loading = false;
            this.$message.success("接口密钥已更新");
          });
        })
        .catch(() => {
          this.$message.info("已取消");
        });
    },
    buyAuth() {
      this.$refs.authRefs.openDialog();
      // this.$message.success("购买授权功能暂未开放");
    },
  },
};
</script>

<style lang="scss" scoped></style>
